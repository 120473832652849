var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Thống kê tổng tiền đã thanh toán của khoản thu",
        visible: _vm.dialogVisible,
        "before-close": _vm.closeDialog,
        "close-on-click-modal": false,
        width: "35%",
        top: "3vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            position: "relative",
            "z-index": "1",
            "margin-top": "5px",
          },
        },
        [
          _c(
            "el-radio-group",
            {
              staticStyle: { "margin-right": "20px" },
              on: {
                change: function ($event) {
                  return _vm.changeTypeMethod()
                },
              },
              model: {
                value: _vm.dataSearch.type,
                callback: function ($$v) {
                  _vm.$set(_vm.dataSearch, "type", $$v)
                },
                expression: "dataSearch.type",
              },
            },
            [
              _c("el-radio", { attrs: { label: "month" } }, [
                _vm._v("Xem theo tháng"),
              ]),
              _c("el-radio", { attrs: { label: "date" } }, [
                _vm._v("Xem theo ngày"),
              ]),
            ],
            1
          ),
          _vm.showMonth
            ? _c(
                "el-select",
                {
                  staticStyle: { width: "120px", "margin-right": "5px" },
                  attrs: { clearable: "", placeholder: "Chọn tháng" },
                  on: {
                    change: function ($event) {
                      return _vm.getDataInitial()
                    },
                  },
                  model: {
                    value: _vm.dataSearch.month,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataSearch, "month", $$v)
                    },
                    expression: "dataSearch.month",
                  },
                },
                _vm._l(_vm.monthList, function (item) {
                  return _c("el-option", {
                    key: item,
                    attrs: { label: item, value: item },
                  })
                }),
                1
              )
            : _vm._e(),
          _vm.showDate
            ? _c("el-date-picker", {
                staticClass: "input-common",
                staticStyle: { width: "265px" },
                attrs: {
                  "value-format": "yyyy-MM-dd",
                  format: "dd-MM-yyyy",
                  type: "daterange",
                  "unlink-panels": "",
                  "range-separator": "-",
                  "start-placeholder": "Ngày bắt đầu",
                  "end-placeholder": "Ngày kết thúc",
                },
                on: { change: _vm.changeDateMethod },
                model: {
                  value: _vm.dataSearch.dateList,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataSearch, "dateList", $$v)
                  },
                  expression: "dataSearch.dateList",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("h1", { staticClass: "money-total" }, [
        _vm._v("\n    Tổng tiền:\n    "),
        _c("span", { staticStyle: { "font-weight": "normal" } }, [
          _vm._v(_vm._s(_vm._f("formatCurrencyNew")(_vm.totalMoney))),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "tab-infor-button" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "medium" },
              on: { click: _vm.closeDialogByButton },
            },
            [
              _c("i", { staticClass: "el-icon-circle-close" }),
              _c("span", [_vm._v("Đóng")]),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }